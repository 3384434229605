<template>
  <div class="doctor-main-component w-full">
    <h1 class="text-gray-900 w-full text-left font-semibold text-3xl">
      Titulo
    </h1>
    <p class="text-gray-700 w-full text-left">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eius fuga
      vel harum id aliquid culpa quis non consequuntur consequatur.
      Reprehenderit, perferendis hic. Quas ea iure ex dolorem. Fugit, unde?
    </p>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {};
  },
};
</script>

<style scoped>
.doctor-main-component {
  min-height: 80vh;
}
</style>
